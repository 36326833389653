import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const Aboutpage = () => {
  return (
    <Layout>
      <SEO
        title="About"
        description=" The Octet Institute aims to provide sufficient knowledge to start a
        career as a VLSI engineer."
      />
      <h1>About Us</h1>
      <p>
        The Octet Institute aims to provide sufficient knowledge to start a
        career as a <b>VLSI engineer</b> with more focus on front end side of
        the VLSI design cycle. Starting with <em>Verilog</em>, we will be slowly
        bringing more contents related to <em>System Verilog</em>, <em>UVM</em>{" "}
        and other related topics.
      </p>
      <p>
        Stay tuned to explore more topics related to VLSI and start a new
        journey as VLSI professionals with us.
      </p>
    </Layout>
  )
}

export default Aboutpage
